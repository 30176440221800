import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, from, interval, race } from 'rxjs';
import { switchMap, first, take, tap } from 'rxjs/operators';
import { TokenServiceService } from './token-service.service';
import { UserInfosService } from '../autenticacao/usuario/user-infos.service';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { Token } from '@angular/compiler';
import { Console } from 'console';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private token: TokenServiceService, private user: UserInfosService) {}


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return from(this.aguardarToken(req)).pipe(
      switchMap(() => {
        let body = req.body;
        let clonedRequest = req;
        let bodyComplemento:string = ""

        try {
          body.data = {
            ...body.data

          };


        } catch (error) {
          console.log("Url: " + req.url)
          console.log("body: " + body)
        }


        if (req.url.startsWith("https://api.nutrien.nbs.")) {
          body.data = {
            ...body.data
            //,
           // "jwt-email": this.user.getUserEmail(),

          };
        }

        console.log("req_url" + req.url);
        if (req.url.startsWith("https://mostqiapi.com")) {
          console.log("if most_qi_ocr");
          clonedRequest = req.clone({
            setHeaders:{
              "Authorization": 'Token ' + this.token.secrets.tokens.mostqi,
              'Content-Type': 'application/json'
            },
            body: body
          });
        }else if (req.url.startsWith("https://api.nutrien.nbs.") && !req.url.includes("nutrien-gcpfn-authenticate-portal")){
                    console.log("if GCP");
            clonedRequest = req.clone({
              setHeaders:{
                "Token": this.token.userAccessToken,
                "Authorization": "Bearer " + this.token.jwt
              },
              body: body
            });
        }
        else if (req.url.startsWith("https://app.pipefy.com/queries")){
          console.log(" pipefy ");
            clonedRequest = req.clone({
              setHeaders:{
                'Content-Type': 'application/json',
                'Authorization':  this.token.secrets.tokens.pipefy
              },
              body: body
            });
        }






        // Passa a requisição clonada para o próximo handler
        return next.handle(clonedRequest);


        // const modifiedRequest = req.clone({

        //   setHeaders: {
        //     "Token": this.token.userAccessToken,
        //     "Authorization": this.token.jwt
        //     // "Email": this.user.getUserEmail()
        //   },
        //   body: body
        // });

        // return next.handle(modifiedRequest);
      })
    ).pipe(
      tap(
        (event) => {

        },
        (err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            // console.log("Erro de autenticação da chamada: ", err);
            Swal.fire({
              title: 'Ops, sua sessão expirou!',
              text: 'Por favor, saia e logue novamente.',
              icon: 'error',
              confirmButtonText: 'Sair',
              confirmButtonColor: '#ff6961',
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then( ()=> {
              window.location.reload();
            });
          }

        }
      )
    );
  }

  private async aguardarToken(req: HttpRequest<any>): Promise<void> {
    while ((!this.token.userAccessToken || this.user.getUserEmail() == undefined ) && !req.url.includes("microsoft") && !environment.production) {
      await new Promise((resolve) => setTimeout(resolve, 100)); // Aguarda 200 milissegundos
    }
  }
}
